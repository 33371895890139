import './App.css';
import React from 'react';
import Routes from './Routes';

import { muiTheme } from './theme';
import { MuiThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import './Sys/App.scss';
// import { CookieProvider } from 'Context/cookieProvider';
import { AuthProvider } from 'Context';

class App extends React.Component {

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <AuthProvider>
        {/* <CookieProvider> */}
        <MuiThemeProvider theme={muiTheme}>
          <CssBaseline />
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
          <Routes />
        </MuiThemeProvider>
        {/* </CookieProvider> */}
      </AuthProvider>
    )
  }
}

export default App;