import React, { createContext, useContext, useEffect, useMemo, useState } from "react";
import Keycloak from "keycloak-js";
import CircularProgress from "@material-ui/core/CircularProgress";
import Box from "@material-ui/core/Box";
import "../Sys/config";

const KeycloakContext = createContext();

export const KeycloakProvider = ({ children }) => {
  const getKeycloakUrl = () => {
    const hostname = window.location.hostname;
    if (hostname.includes("deviare.africa")) {
      return global.keycloakURL;
    } else if (hostname.includes("digitalskillsonline.net")) {
      return global.keycloakDigitalSkills;
    }
    return global.keycloakURL;
  };

  const keycloak = useMemo(() => {
    const keycloakConfig = {
      url: getKeycloakUrl(),
      realm: global.keycloakRealm,
      clientId: global.keycloakClientId,
    };
    return new Keycloak(keycloakConfig);
  }, []);

  const [authenticated, setAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    keycloak.init({ onLoad: "login-required", checkLoginIframe: false })
      .then((auth) => {
        setAuthenticated(auth);
        setLoading(false);
        if (!auth) {
          keycloak.login();
        }
      })
      .catch((err) => {
        console.error("Keycloak initialization failed:", err);
        setLoading(false);
      });
  }, [keycloak]);

  const login = () => keycloak.login();
  const logout = () => {
    document.cookie.split(";").forEach((cookie) => {
      document.cookie = cookie
        .replace(/^ +/, "")
        .replace(/=.*/, `=;expires=${new Date(0).toUTCString()};path=/`);
    });
    keycloak.logout();
  };

  const contextValue = {
    keycloak,
    authenticated,
    login,
    logout,
    setAuthenticated
  };

  return (
    <KeycloakContext.Provider value={contextValue}>
      {loading ? (
        <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
          <CircularProgress />
        </Box>
      ) : (
        children
      )}
    </KeycloakContext.Provider>
  );
};

export const useKeycloak = () => {
  return useContext(KeycloakContext);
};
