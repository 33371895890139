import React from "react";
import { Redirect, Route } from "react-router-dom";
import { loginUser, useAuthDispatch, useAuthState } from "../Context";
import { useKeycloak } from "Context/keyCloakProvider";

var apiUrl = global.platformURI;

const AppRoutes = ({ component: Component, path, isPrivate, ...rest }) => {
  const userDetails = useAuthState();
  const dispatch = useAuthDispatch();
  const { keycloak, authenticated } = useKeycloak();
 
  if (authenticated) {
    // Check if the page has already been refreshed
    const hasRefreshed = localStorage.getItem("hasRefreshed");

    if (!hasRefreshed) {
      localStorage.setItem("SSO", keycloak?.token);
      localStorage.setItem("ID:SSOToken", keycloak?.idToken);
      localStorage.setItem("session_state", keycloak?.tokenParsed?.session_state);
      localStorage.setItem("isLogin", true);
      localStorage.setItem("user", keycloak?.tokenParsed?.preferred_username);
      localStorage.setItem("refreshToken", keycloak?.refreshToken);
 
      // Make the API call to the external platform
      fetch(`${apiUrl}main/authorize`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ sso_token: keycloak?.token }),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Failed to send token to the platform.");
          }
          return response.json();
        })
        .then((data) => {
          dispatch({ type: 'LOGIN_SUCCESS', payload: data.data });
          const user_auth = {
            ...data.data,
            isLogin: true,
            user: keycloak?.tokenParsed?.preferred_username,
            company_id: data.data.customers ? data.data.customers[0] : null,
            company_logo: data.data.company_logo ? data.data.company_logo : null,
          };
          localStorage.setItem("currentUser", data.data);
          localStorage.setItem("token", data.data.token);
          localStorage.setItem("role", data.data.role);
          localStorage.setItem("uuid", data.data.uuid);

          localStorage.setItem("user_auth", JSON.stringify(user_auth));
          localStorage.setItem("ssoToken", data.data.token);
          localStorage.setItem("company_name", data?.data?.company_name);
          localStorage.setItem(
            "company_id",
            data?.data?.customers ? data?.data?.customers[0] : "null"
          );

          // Set the refresh flag and reload the page
          localStorage.setItem("hasRefreshed", "true");
          window.location.reload();
        })
        .catch((error) => {
          console.error("Error during token transfer:", error);
        });
    } else {
      console.log("Page already refreshed; skipping token storage and API call.");
    }
  } else {
    console.log("User not authenticated; skipping token storage and API call.");
  }

  return (
    <Route
      path={path}
      render={(props) => {
        if (path === "/" && authenticated) {
          return <Redirect to={{ pathname: "/dashboard" }} />;
        }
        if (isPrivate && !authenticated) {
          return <Redirect to={{ pathname: "/" }} />;
        }
        return <Component {...props} />;
      }}
      {...rest}
    />
  );
};

export default AppRoutes;