import { useAuthDispatch, useAuthState } from 'Context/context';
import React, { useState, createContext, useEffect } from 'react';
import { SkillsAndTransformation } from 'Services/SkillsAndTransformation';
import { USER_ROLES } from 'utils/constants';

const skillsAndTransformation = new SkillsAndTransformation();

export const CourseContext = createContext();

function CourseProvider({ children }) {
  const [courseCatalogues, setCourseCatalogues] = useState({});
  
  const dispatch = useAuthDispatch();
  const { userDetails } = useAuthState(dispatch);

  useEffect(() => {
    const userUUID = localStorage.getItem("uuid");
    const userRole = localStorage.getItem("role");

    if (userUUID && userRole === USER_ROLES.USER) {
      getCourseCatalogeData();
    }
  }, [ userDetails]);

  const getCourseCatalogeData = async () => {
    try {
      const courseList = await skillsAndTransformation.getCourseCatalogue();
      if (courseList?.status) {
        setCourseCatalogues(courseList);
      }
    } catch (error) {
      console.error("Error fetching course catalogues:", error);
    }
  };
  
  return (
    <CourseContext.Provider value={{ courseCatalogues }}>
      {children}
    </CourseContext.Provider>
  );
}

export default CourseProvider;
