import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Layout from "../../Components/layouts";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import aiImg from '../../Assets/images/ai.png';
import projectsImg from '../../Assets/images/projects.png';
import Typography from '@material-ui/core/Typography';
import { Button } from '@material-ui/core';
import { AssesmentsSummary } from '../../utils/constants'
import commonStyles from 'theme/commonStyles';
import variables from '../../Sys/variable.scss';

const useStyles = makeStyles((theme) => ({
    heading: {
        color: variables.labelcolor,
        fontSize: '23px',
        fontWeight: '500',
        marginBottom: 20
    },
    root: {
        display: 'flex',
        height: 180,
        background: variables.headerColor,
        boxShadow: "0px 4px 30px rgba(12, 41, 84, 0.1)",
        borderRadius: 5
    },
    cardDetails: {
        display: 'flex',
        flexDirection: 'column',
    },
    content: {
        flex: '1 0 auto',
        maxWidth: '80%',
    },
    cover: {
        width: 174,
        height: 132,
        paddingTop: 17,
        marginTop: 25,
        marginLeft: 20,
        borderRadius: 5,
        maxWidth: '20%'
    },
    controls: {
        display: 'flex',
        alignItems: 'center',
        paddingLeft: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    cardTitle: {
        color: variables.labelcolor
    },
    cardDesc: {
        color: variables.darkThemeText,
    },
    cardSubTitle: {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        color: "#3D7DDD",
    },
    title: {
        fontSize: "1.3rem"
    }
}));


const DynamicCard = ({ item }) => {
    const classes = useStyles();

    return (
        <>
            <Grid key={item.title} item xs={item.width}>
                <Card className={classes.root}>
                    <CardMedia
                        className={classes.cover}
                        image={item.image}
                        title={item.title}
                    />
                    <CardContent className={classes.content}>
                        <Typography className={classes.cardTitle}>
                            {item.title}
                        </Typography>
                        <Typography className={classes.title} variant="h1" color="primary">
                            {item.subTitle}
                        </Typography>
                        <Typography variant="h1" color="primary">
                            {item.totalCount}
                        </Typography>
                        <div style={{ display: 'flex', justifyContent: "space-between", marginTop: 10 }}>
                            <Button style={{ fontSize: 12, borderRadius: 5, padding: '12px 55px' }} variant="outlined" color="primary">
                                Launch</Button>
                        </div>
                    </CardContent>
                </Card>
            </Grid>
        </>
    )
}

const Assesments = () => {
    
    const classes = useStyles();
    const commonClasses = commonStyles();
    
    const userCardData = [
        {
            title: 'Assignments are Due',
            subTitle: 'Digital Readiness Assignment',
            image: aiImg,
            width: 6
        },
        {
            title: 'Assignments are Due',
            subTitle: 'Game Changer Index Assessment',
            image: projectsImg,
            width: 6
        },
        {
            title: 'Assignments are Due',
            subTitle: 'Digital Talent Maturity Model',
            image: aiImg,
            width: 6
        },
    ];

    return (
        <Layout>
            <main>
                <div className={commonClasses.contentWrapper}>
                    <Typography
                        variant="subtitle2"
                        className={classes.heading}
                        color="textPrimary">
                        My Assessments
                    </Typography>
                    <Typography variant="subtitle1" style={{ color: variables.darkThemeText }} >
                        {AssesmentsSummary}
                    </Typography>
                    <Grid container spacing={2} style={{ marginTop: 24 }}>
                        {userCardData.map((item) => (
                            <DynamicCard item={item} />
                        ))}
                    </Grid>
                </div>
            </main>
        </Layout>
    );
}

export default Assesments;