import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Layout from "../../../Components/layouts";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import { Button, CircularProgress } from '@material-ui/core';
import axios from 'axios';
import { AssesmentsSummary } from '../../../utils/constants';
import aiImg from '../../../Assets/images/ai.png';

const ApiUrl = global.platformURI;

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        height: 180,
        background: '#FFFFFF',
        boxShadow: "0px 4px 30px rgba(12, 41, 84, 0.1)",
        borderRadius: 5
    },
    cover: {
        width: 174,
        height: 132,
        paddingTop: 17,
        marginTop: 25,
        marginLeft: 20,
        borderRadius: 5,
        maxWidth: '20%'
    },
    content: {
        flex: '1 0 auto',
        maxWidth: '80%',
    },
    loaderContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '800px',
    }
}));

const Assessments = () => {
    const classes = useStyles();
    const [assessments, setAssessments] = useState([]);
    const [iframeUrl, setIframeUrl] = useState('');
    const [isIframeVisible, setIsIframeVisible] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        fetchAssessments();
    }, []);

    const fetchAssessments = async () => {
        try {
            const res = await axios.get(`${ApiUrl}main/api/user-assigned-assessments/`);
            const filteredAssessments = res.data?.data?.filter(assessment => 
                assessment?.assessment_license_id?.assessment_id?.name === "Digital Talent Maturity Model"
            );
            setAssessments(filteredAssessments);
        } catch (error) {
            console.error("Error fetching assessments:", error);
        } finally {
            setLoading(false);
        }
    };

    const newLaunchAssessment = async (assessment) => {
        try {
            setLoading(true);
            setIsIframeVisible(true);
            const res = await axios.get(`${ApiUrl}main/rdaurl`);
            if (res.data?.data?.rdaurl) {
                setIframeUrl(res.data?.data?.rdaurl);
            }
        } catch (error) {
            console.error("Error launching assessment:", error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Layout>
            <main>
                {loading ? (
                    <div className={classes.loaderContainer}>
                        <CircularProgress color="primary" />
                    </div>
                ) : (
                    !isIframeVisible ? (
                        <>
                            <h2>My Assessments</h2>
                            <Typography variant="subtitle1">{AssesmentsSummary}</Typography>
                            <Grid container spacing={2} style={{ marginTop: 10 }}>
                                {assessments.length > 0 ? (
                                    assessments.map((assessment, index) => (
                                        <Grid item xs={6} key={index}>
                                            <Card className={classes.root}>
                                                <CardMedia className={classes.cover} image={aiImg} title={assessment.title} />
                                                <CardContent className={classes.content}>
                                                    <Typography variant="h1" color="primary">
                                                        {assessment?.assessment_license_id?.assessment_id?.name}
                                                    </Typography>
                                                    <Typography variant="h1" color="primary">
                                                        {/* {assessment?.assessment_license_id?.count || 0} */}
                                                    </Typography>
                                                    <Button
                                                        onClick={() => newLaunchAssessment(assessment)}
                                                        style={{ fontSize: 12, borderRadius: 5, padding: '12px 55px',marginTop:20 }}
                                                        variant="outlined"
                                                        color="primary"
                                                    >
                                                        Launch
                                                    </Button>
                                                </CardContent>
                                            </Card>
                                        </Grid>
                                    ))
                                ) : (
                                    <Typography variant="h6" color="textSecondary">
                                        No available assessments.
                                    </Typography>
                                )}
                            </Grid>
                        </>
                    ) : (
                        <div style={{ marginTop: 20 }}>
                            {loading && (
                                <div className={classes.loaderContainer}>
                                    <CircularProgress color="primary" />
                                </div>
                            )}
                            <iframe
                                src={iframeUrl}
                                width="100%"
                                height="800px"
                                frameBorder="0"
                                title="Assessment"
                                onLoad={() => setLoading(false)}
                                style={{ display: loading ? 'none' : 'block' }}
                            ></iframe>
                        </div>
                    )
                )}
            </main>
        </Layout>
    );
};

export default Assessments;
