import { authService } from 'Services/AuthService';
import { getDeep } from 'utils';
import { ERROR_MESSAGE, USER_ROLES } from '../utils/constants';

import { Cookies } from 'react-cookie';

const cookies = new Cookies();
const doLoginApi = user => {
  return authService
    .login(user.username, user.password)
    .then(response => {
      if (response.status) {
        const message = { ...response.data };
        const user_auth = {
          ...message,
          isLogin: true,
          user: user.username,
          company_id: message.customers ? message.customers[0] : null,
          company_logo: message.company_logo ? message.company_logo : null
        };
        localStorage.setItem('user_auth', JSON.stringify(user_auth));
        localStorage.setItem('user', user.username);
        localStorage.setItem('isLogin', 'true');
        localStorage.setItem('uuid', message.uuid);
        localStorage.setItem('company_name', message.company_name);
        localStorage.setItem(
          'company_id',
          message.customers ? message.customers[0] : 'null'
        );
        localStorage.setItem('enterprise_token',message.token);
        localStorage.setItem('SSO', message.SSO);
        localStorage.setItem('ssoToken', message.token);
        localStorage.setItem(
          'apprenticeship',
          getDeep(message, 'registered.apprenticeship', 0)
        );
        localStorage.setItem('session_data', message.session_data);
        localStorage.setItem('IDP:SSOToken', message['IDP:SSOToken']);
        localStorage.setItem('role', message.role);
        localStorage.setItem('sub_role', message.sub_role);
        // localStorage.setItem(
        //   'company_logo',
        //   message.company_logo ? message.company_logo : 'null'
        // );
        localStorage.setItem('identityToken', message.identity_token);
        const pr = message.project_id || false;
        if (pr) {
          localStorage.setItem('project_id', pr);
        }
        const existingCookie = document.cookie
          .split('; ')
          .find(row => row.startsWith(`${'ssoToken'}=`));

        if (!existingCookie) {
          document.cookie = `${'ssoToken'}=${
            message.token
          }; path=/; secure; samesite=strict`;
          setCookie('user_auth', JSON.stringify(user_auth));
          setCookie('user', user_auth.username);
          setCookie('isLogin', 'true');
          setCookie('uuid', message?.uuid);
          setCookie('company_name', message?.company_name);
          setCookie(
            'company_id',
            message?.customers ? message?.customers[0] : 'null'
          );
          setCookie('SSO', message?.SSO);
          setCookie('ssoToken', message?.token);
          setCookie('enterprise_token', message?.token);
          setCookie('session_data', message?.session_data);
          setCookie('IDP:SSOToken', message['IDP:SSOToken']);
          setCookie('role', message?.role);
          setCookie('sub_role', message?.sub_role);
        }
        return { ...response, role: response?.role || USER_ROLES.USER };
      } else {
        return response;
      }
    })
    .catch(function (error) {
      //no data
      console.log(error);
      throw error;
    });
};

// function setCookie(name, value, options = {}) {
//   let cookieString = `${encodeURIComponent(name)}=${encodeURIComponent(value)}`;
//   options = {
//     path: '/', // Make the cookie accessible across all paths
//     domain: '.deviare.africa', // Set the cookie to be accessible on all subdomains
//     secure: window.location.protocol === 'https:', // Ensure secure cookies in production
//     sameSite: 'None',
//     ...options,
//   };
 
//   // Set domain
//   if (options.domain) {
//     cookieString += `; domain=${options.domain}`;
//   }
 
//   // Set path
//   cookieString += `; path=${options.path}`;
 
//   // Set secure flag if secure is true
//   if (options.secure) {
//     cookieString += `; secure`;
//   }
 
//   // Set SameSite policy
//   if (options.sameSite) {
//     cookieString += `; SameSite=${options.sameSite}`;
//   }
 
//   document.cookie = cookieString;
// }

// function setCookie(name, value, options = {}) {
//   const defaultOptions = {
//     path: '/',
//     domain: '.deviare.africa',
//     secure: true,
//     sameSite: 'None',
//     ...options,
//   };
 
//   // Encode name and value
//   const cookieName = encodeURIComponent(name);
//   const cookieValue = encodeURIComponent(value);
 
//   // Construct the cookie string
//   let cookieString = `${cookieName}=${cookieValue}; path=${defaultOptions.path}; SameSite=${defaultOptions.sameSite}`;
 
//   // Add secure and domain if applicable
//   if (defaultOptions.secure) {
//     cookieString += '; Secure';
//   }
//   if (defaultOptions.domain) {
//     cookieString += `; domain=${defaultOptions.domain}`;
//   }
 
//   document.cookie = cookieString;
 
//   cookies.set(name, value, {
//     path: defaultOptions.path,
//     domain: defaultOptions.domain,
//     secure: defaultOptions.secure,
//     sameSite: defaultOptions.sameSite,
//     ...options,
//   });
// }

export function setCookie(name, value, options = {}) {
  try {
    const isLocalhost = window.location.hostname === 'localhost';

    const defaultOptions = {
      path: '/',
      domain: isLocalhost ? undefined : '.deviare.africa',
      secure: !isLocalhost,
      sameSite: isLocalhost ? 'Lax' : 'None',
      ...options,
    };

    // Encode name and value
    const cookieName = encodeURIComponent(name);
    const cookieValue = encodeURIComponent(value);

    // Construct the cookie string
    let cookieString = `${cookieName}=${cookieValue}; path=${defaultOptions.path}; SameSite=${defaultOptions.sameSite}`;

    // Add secure and domain attributes if applicable
    if (defaultOptions.secure) {
      cookieString += '; Secure';
    }
    if (defaultOptions.domain) {
      cookieString += `; domain=${defaultOptions.domain}`;
    }

    // Set the cookie via document.cookie
    document.cookie = cookieString;

    // Optionally, use a cookie library if needed
    if (typeof cookies !== 'undefined' && cookies.set) {
      cookies.set(name, value, {
        path: defaultOptions.path,
        domain: defaultOptions.domain,
        secure: defaultOptions.secure,
        sameSite: defaultOptions.sameSite,
        ...options,
      });
    }
  } catch (e) {
    console.log('error setting cookie', e);
  }
}

export async function loginUser(dispatch, loginPayload) {
  try {
    dispatch({ type: 'REQUEST_LOGIN' });
    const data = await doLoginApi(loginPayload);
    if (data.status && data.data) {
      localStorage.setItem('currentUser', JSON.stringify(data.data));
      dispatch({ type: 'LOGIN_SUCCESS', payload: data.data });
      return data;
    }
    throw ERROR_MESSAGE.INVALID_CRED;
  } catch (error) {
    dispatch({ type: 'LOGIN_ERROR', error: error });
  }
}

// export function clearCookies() {
//    const cookies = document.cookie.split("; ");
//    cookies.forEach((cookie) => {
//     const cookieName = cookie.split("=")[0].trim();
//      document.cookie = `${cookieName}=; path=/`;
//      document.cookie = `${cookieName}=; domain=.deviare.africa; path=/;  Secure; SameSite=None`;
//    });
// }

function clearCookies(options = {}) {
  // Get all cookies
  const allCookies = cookies.getAll();

  // Default options for cookie removal
  const defaultOptions = {
    path: '/',
    domain: '.deviare.africa',
    ...options,
  };

  // Loop through and remove each cookie
  Object.keys(allCookies).forEach(cookieName => {
    cookies.remove(cookieName, {
      path: defaultOptions.path,
      domain: defaultOptions.domain,
      ...options,
    });
  });
   // Default options for cookie removal
   const defaultOptionsplatform = {
    path: '/',
    domain: window.location.origin,
    ...options,
  };
cookies.remove("ssoToken");
  // Loop through and remove each cookie
  Object.keys(allCookies).forEach(cookieName => {
    cookies.remove(cookieName, {
      path: defaultOptionsplatform.path,
      domain: defaultOptionsplatform.domain,
      ...options,
    });
  });
}


export async function logout(dispatch) {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  localStorage.clear();
  clearCookies();
  document.cookie.split(";").forEach((cookie) => {
    document.cookie = cookie
      .replace(/^ +/, "") // Trim spaces
      .replace(/=.*/, `=;expires=${new Date(0).toUTCString()};path=/`);
  });
  dispatch({ type: 'LOGOUT' });
}
